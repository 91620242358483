import React from 'react';
import Slider from 'react-slick';
import './FasciaBlog.scss';
import '../../css/plugins/slick-custom.scss';
import CardBlog from './CardBlog';
import { Wrapper } from '../../utils/components';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import { useWithMobileResizeListener } from '../../utils/hooks/useWithMobileResizeListener';
import Title from '../../molecules/title/title';
import { getCommonOrganismData } from '../../utils/functions';
import classNames from 'classnames';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import withPreview from '../../utils/with-preview';
import { Link } from '../../organisms/link';
import Icon from '../../atoms/icon';

const FasciaBlog = ({ data, considerMenuSpace, relatedArticles }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    title,
    titleHeadingTag,
    titleIconId,
    marginTop,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const fasciaBlog = data;

  const occhiello = fasciaBlog?.moduleTitle?.content?.occhielloTitolo?.value;
  const cardsNumber = fasciaBlog?.products?.length;
  const disableSliderOver = fasciaBlog?.disableSliderOver?.value;
  const disableSliderUnder = fasciaBlog?.disableSliderUnder?.value;
  const isMobile = useWithMobileResizeListener(992);
  const tagTitle = fasciaBlog?.tagTitleBlogPost
    ? fasciaBlog?.tagTitleBlogPost?.value[0]
    : 'p';
  const link = fasciaBlog?.link?.value;
  const linkLabel = fasciaBlog?.link?.content?.linkLabel?.value;
  const iconSvgIdLink = fasciaBlog?.link?.content?.iconSvgIdLink?.value;
  // const linkIconThickness = fasciaBlog?.link?.content?.linkIconThickness?.value;

  const settings = {
    infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
    dots: true,
    speed: 500,
    arrow: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slide: '',
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={classNames(
          'slick-dots__custom-dot',
          relatedArticles && 'slick-dots__custom-dot--related'
        )}
      ></div>
    ),
  };

  const renderMobile = () => {
    if (disableSliderUnder) {
      return (
        <div className='fascia-blog__cards-wrapper'>
          {fasciaBlog?.products?.map((product, i) => (
            <CardBlog tag={tagTitle} key={i} data={product.value} />
          ))}
        </div>
      );
    }
    return (
      <Slider {...settings} className='fascia-blog__slider'>
        {fasciaBlog?.products?.map((product, i) => (
          <CardBlog tag={tagTitle} key={i} data={product.value} />
        ))}
      </Slider>
    );
  };

  const renderDesktop = () => {
    if (disableSliderOver || cardsNumber <= 3) {
      return (
        <div className='fascia-blog__cards-wrapper'>
          {fasciaBlog?.products?.map((product, i) => (
            <CardBlog tag={tagTitle} key={i} data={product.value} />
          ))}
        </div>
      );
    }
    return (
      <Slider {...settings} className='fascia-blog__slider'>
        {fasciaBlog?.products?.map((product, i) => (
          <CardBlog tag={tagTitle} key={i} data={product.value} />
        ))}
      </Slider>
    );
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-code='C-89'
      data-module-name='fascia-blog'
      className={classNames(`fascia-blog ${marginTop}`, {
        'fascia-blog--colored-background': backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      <div className='fascia-blog__container wrapper'>
        {(title || titleIconId) && (
          <Title
            titleIconId={titleIconId}
            titleIconThickness={titleIconThickness}
            tag={titleHeadingTag}
            titleIntro={occhiello}
          >
            {title}
          </Title>
        )}
        {isMobile ? renderMobile() : renderDesktop()}
        {link && linkLabel && (
          <Link className='fascia-blog__bottom-link' to={link}>
            <span className='fascia-blog__bottom-link-label'>{linkLabel}</span>
            {iconSvgIdLink && <Icon icon={iconSvgIdLink} />}
          </Link>
        )}
      </div>
    </Wrapper>
  );
};

export default withPreview(FasciaBlog);
