import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getBlogCategoryColor } from '../../utils/functions';
import withPreview from '../../utils/with-preview';
import { Wrapper } from '../../utils/components';

const CardBlog = ({ tag, data }) => {
  const post = data;
  const title =
    post?.cardDetails?.content?.cardTitle?.value ||
    post?.detailPageTitle?.value;
  const image = post?.blogPost?.content?.imageDetailPost?.node;
  const link = post?.link;
  const categories = post?.liferayFields?.taxonomyCategoryBriefs;

  return (
    <section className='card-blog'>
      {image && (
        <GatsbyImage
          className='card-blog__image'
          image={getImage(image)}
          alt={title}
        />
      )}
      <div className='card-blog__text-wrapper'>
        {categories && (
          <ul className='card-blog__categories'>
            {categories.map((category, i) => (
              <li
                className='card-blog__category'
                key={i}
                style={{
                  '--bg-category': getBlogCategoryColor(category),
                }}
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}

        {title && link && (
          <a href={link} title={title}>
            <Wrapper tag={tag} className='card-blog__title'>
              {title}
            </Wrapper>
          </a>
        )}
      </div>
    </section>
  );
};

export default withPreview(CardBlog);
